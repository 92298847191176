<template>
    <div v-loading="$wait.is('loading.employee_reminders')" class="w-full card" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal @success="getAll" />

        <ModalAddReminder
            :visible="modalAddVisible"
            :employeeUuid="employeeUuid"
            @refresh="getAll"
            @close="modalAddVisible = false"
        />

        <div class="flex items-center justify-between border-b border-gray-300 pb-4 mb-5">
            <h1>{{ $t('employee_details.reminders') }}</h1>
            <el-button type="primary" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <EmployeeRemindersTable
            :employeeReminders="remindersData"
            @handleDelete="handleDelete"
        />

        <NoDataInformation :data="noDataInformation" waitKey="loading.employee_reminders" />
    </div>
</template>

<script>
export default {
    components: {
        ModalAddReminder:       () => import(/* webpackChunkName: "EmployeeReminders/ModalAddReminder" */ './modals/ModalAddReminder'),
        EmployeeRemindersTable: () => import(/* webpackChunkName: "EmployeeReminders/EmployeeRemindersTable" */ './components/EmployeeRemindersTable'),
    },

    data() {
        return {
            noDataInformation: false,
            employeeUuid:      this.$route.params.uuid,
            remindersData:     [],
            modalAddVisible:   false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'employee_reminders');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            try {
                this.$wait.start('loading.employee_reminders');
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/reminders`);
                this.remindersData = data;
                this.noDataInformation = data.length;
            } finally {
                this.$wait.end('loading.employee_reminders');
            }
        },

        showAddModal() {
            this.modalAddVisible = true;
        },

        async handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/employees/employees/${uuid}/reminders`,
                actionType: 'destroy',
            });
        },
    },
};
</script>
